import React from 'react';
import { Link } from 'react-router-dom';

const AdminPage = () => {
    return (
        <div>
            <h2>Admin Dashboard</h2>
            <Link to="/register">Register a new user</Link>
        </div>
    );
};

export default AdminPage;
