import axios from 'axios';
import {jwtDecode} from 'jwt-decode';

const getToken = () => localStorage.getItem('token');

const isTokenExpired = (token) => {
    if (!token) return true;

    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    return decoded.exp < currentTime;
};

const axiosInstance = axios.create({
    baseURL: '/api',
});

axiosInstance.interceptors.request.use((config) => {
    const token = getToken();
    if (token) {
        if (isTokenExpired(token)) {
            localStorage.removeItem('token');
            window.location.href = '/login';
            return Promise.reject(new Error('Token expired'));
        } else {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

axiosInstance.interceptors.response.use(
    response => response,
    error => {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('token');

            window.location.href = '/login';
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
