import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import axiosInstance from "../AxiosInstance";

const CustomerDropdown = ({ onCustomerSelected }) => {
    const [customerGroups, setCustomerGroups] = useState([]);

    useEffect(() => {
        fetchGroupedCustomers();
    }, []);

    const fetchGroupedCustomers = async () => {
        try {
            const response = await axiosInstance.get('/customers/grouped');
            const optionsWithGroup = response.data.flatMap(group =>
                group.customers.map(customer => ({
                    ...customer,
                    channelName: group.channelName
                }))
            );
            setCustomerGroups(optionsWithGroup);
        } catch (error) {
            console.error('Failed to fetch customers:', error);
        }
    };


    return (
        <Autocomplete
            id="grouped-customers-dropdown"
            options={customerGroups}
            groupBy={(option) => option.channelName}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => <TextField {...params} label="Select a Customer" />}
            onChange={(event, value) => onCustomerSelected(value?.customerId)}
            sx={{ width: 300 }}
        />
    );
};

export default CustomerDropdown;
