import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import axiosInstance from "../AxiosInstance";

function ForecastDropdown({ selectedForecastId, onForecastSelected }) {
    const [forecasts, setForecasts] = useState([]);
    const [selectedForecast, setSelectedForecast] = useState(null);

    const getToken = () => localStorage.getItem('token');

    useEffect(() => {
        fetchForecasts();
    }, []);

    useEffect(() => {
        if (selectedForecastId && forecasts.length > 0) {
            const selected = forecasts.find(f => f.forecastId === parseInt(selectedForecastId, 10));
            setSelectedForecast(selected || null);
        }
    }, [selectedForecastId, forecasts]);

    const fetchForecasts = async () => {
        try {
            const token = getToken();
            const response = await axiosInstance.get('/forecasts', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setForecasts(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    const handleForecastChange = (event, value) => {
        setSelectedForecast(value);
        if (value) {
            const { forecastId, startPeriod } = value;
            localStorage.setItem('selectedForecast', JSON.stringify({ forecastId, startPeriod }));
            onForecastSelected(forecastId, startPeriod);
        } else {
            localStorage.removeItem('selectedForecast');
            onForecastSelected(null, null);
        }
    };

    return (
        <div>
            <Autocomplete
                disablePortal
                id="combo-box-forecast"
                options={forecasts}
                getOptionLabel={(forecast) => forecast.name}
                sx={{ width: 300 }}
                value={selectedForecast}
                onChange={handleForecastChange}
                renderInput={(params) => <TextField {...params} label="Year" />}
            />
        </div>
    );
}

export default ForecastDropdown;
