import React, {useCallback, useEffect, useState} from 'react';
import {
    DataGrid, 
    GridActionsCellItem,
    GridRowEditStopReasons,
    GridRowModes,
    GridToolbarContainer
} from '@mui/x-data-grid';
import {
    Alert,
    Button,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText, DialogActions, Paper
} from '@mui/material';
import axiosInstance from '../AxiosInstance';
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import Snackbar from '@mui/material/Snackbar';
import {
    isGreaterThanZero,
    validateString
} from "../Utilities/validation";
import Draggable from "react-draggable";

function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

function PromoEditToolbar(props) {
    const {
        setCustomers,
        setRowModesModel,
        selectedCustomer,
        setSnackbar,
        handleOpenConfirmationDialog,
    } = props;

    const handleClick = () => {
        const customerId = -1;
        const newRow = {
            customerId,
            name: '',
            channelId: 1,
            isNew: true,
            canDelete: true
        };

        setCustomers((oldRows) => {
            return [...oldRows, newRow];
        });

        setRowModesModel((oldModel) => ({
            [customerId]: {mode: GridRowModes.Edit, fieldToFocus: 'name'},
            ...oldModel,
        }));
    };

    const handleDeleteClick = () => {
        if (!selectedCustomer || !selectedCustomer.customerId) {
            console.error("No customer selected for deletion.");
            setSnackbar({children: "No customer selected", severity: "error"});
            return;
        }
        handleOpenConfirmationDialog();
    };

    return (
        <GridToolbarContainer sx={{justifyContent: 'flex-end'}}>
            <IconButton aria-label="create" onClick={handleClick}>
                <AddIcon/>
            </IconButton>
            <IconButton aria-label="delete" onClick={handleDeleteClick}>
                <DeleteIcon/>
            </IconButton>
        </GridToolbarContainer>
    );
}

const CustomerTable = () => {

    const [customers, setCustomers] = useState([]);
    const [channels, setChannels] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [rowModesModel, setRowModesModel] = useState({});
    const [snackbar, setSnackbar] = useState(null);
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

    useEffect(() => {
        fetchChannels();
        fetchCustomers();
    }, []);

    const fetchChannels = async () => {
        try {
            const response = await axiosInstance.get('/channels');
            setChannels(response.data);
        } catch (error) {
            console.error('Error fetching channels:', error);
        }
    };

    const fetchCustomers = async () => {
        try {
            const response = await axiosInstance.get('/customers');
            setCustomers(response.data.map(customer => ({
                ...customer
            })));
        } catch (error) {
            console.error('Error fetching customers:', error);
        }
    };

    const handleCloseSnackbar = () => setSnackbar(null);

    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }

        if (params.reason === GridRowEditStopReasons.escapeKeyDown) {
            const id = params.id;
            const editedRow = customers.find((row) => row.customerId === id);
            if (editedRow && editedRow.customerId === -1) {
                setCustomers(customers.filter((row) => row.customerId !== -1));
            }
        }
    };

    const handleProcessRowUpdateError = useCallback((error) => {
        setSnackbar({children: error.message, severity: 'error'});
    }, []);

    const handlePromotionRowClick = (params) => {
        if (params.row.isNew) {
            setSelectedCustomer(null);
            return;
        }

        setSelectedCustomer(params.row);
    };

    const handleEditClick = (id) => () => {
        setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.Edit}});
    };

    const handleSaveClick = (id) => () => {
        setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.View}});
    };

    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: {mode: GridRowModes.View, ignoreModifications: true},
        });

        const editedRow = customers.find((row) => row.customerId === id);
        if (editedRow.isNew) {
            setCustomers(customers.filter((row) => row.customerId !== id));
        }
    };

    const handleOpenConfirmationDialog = (type) => {
        setOpenConfirmationDialog(true);
    };

    const handleCloseConfirmationDialog = () => {
        setOpenConfirmationDialog(false);
    };

    const handleConfirmDeleteCustomer = async () => {
        if (!selectedCustomer) {
            console.error("Attempt to delete without selecting a customer.");
            setSnackbar({children: "No customer selected for deletion", severity: "error"});
            return;
        }

        try {
            const response = await axiosInstance.delete(`/customers/${selectedCustomer.customerId}`);
            if (response.status === 204) {
                setCustomers((currentCustomers) => currentCustomers.filter(row => row.customerId !== selectedCustomer.customerId));
                setSnackbar({children: "Customer successfully deleted", severity: "success"});
            }
        } catch (error) {
            console.error(`Error deleting the customer: ${error}`);
            setSnackbar({children: `Error deleting the customer: ${error.response?.data || error.message}`, severity: "error"});
        } finally {
            setSelectedCustomer(null);
        }
        handleCloseConfirmationDialog();
    };


    const processRowUpdate = async (newRow) => {
        const updatedRow = {
            ...newRow,
            isNew: false
        };

        if (!validateString(newRow.name)) {
            throw new Error('Please enter a valid value for customer name');
        }

        if (!isGreaterThanZero(newRow.channelId)) {
            throw new Error('Please select a valid channel');
        }

        if (isGreaterThanZero(newRow.customerId)) {
            try {
                await axiosInstance.put(`/customers/${newRow.customerId}`, updatedRow);
                setCustomers(customers.map((row) => (row.customerId === newRow.customerId ? updatedRow : row)));
                setSnackbar({children: 'Customer successfully updated', severity: 'success'});
                //await getPromotions();
            } catch (error) {
                console.error('Error updating the customer:', error);
                let errorMessage = error.response.data;
                throw new Error(errorMessage);
            }
        } else {
            try {
                const response = await axiosInstance.post(`/customers`, updatedRow);
                const createdRow = response.data;
                setCustomers((prevRows) => {
                    return prevRows.map(row => row.customerId === -1 ? createdRow : row);
                });
                setSnackbar({children: 'Customer successfully created', severity: 'success'});
            } catch (error) {
                console.error('Error creating the customer:', error);
                throw new Error('Error creating the customer.');
            }
        }

        return updatedRow;
    };

    const columns = [
        {
            field: 'name',
            headerName: 'Name',
            width: 200,
            editable: true,
        },
        {
            field: 'channelId',
            headerName: 'Channel',
            width: 120,
            editable: true,
            type: 'singleSelect',
            valueOptions: channels.map((channel) => ({
                value: channel.channelId,
                label: channel.name
            })),
            valueFormatter: (value) => {
                const channel = channels.find(f => f.channelId === value);
                return channel ? channel.name : '';
            },
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions: ({id}) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon/>}
                            label="Save"
                            sx={{
                                color: 'primary.main',
                            }}
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon/>}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon/>}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />
                ];
            },
        },
    ];

    return (
        <Box
            sx={{
                height: 500,
                width: '100%',
                '& .actions': {
                    color: 'text.secondary',
                },
                '& .textPrimary': {
                    color: 'text.primary',
                },
            }}
        >
            <DataGrid
                getRowId={(row) => row.customerId}
                rows={customers}
                columns={columns}
                editMode="row"
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                onProcessRowUpdateError={handleProcessRowUpdateError}
                slots={{
                    toolbar: PromoEditToolbar,
                }}
                slotProps={{
                    toolbar: {
                        setCustomers,
                        setRowModesModel,
                        selectedCustomer,
                        setSnackbar,
                        handleOpenConfirmationDialog
                    }
                }}
                onRowClick={handlePromotionRowClick}
                pageSize={10}
                autoHeight
            />
            {!!snackbar && (
                <Snackbar
                    open
                    anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                    onClose={handleCloseSnackbar}
                    autoHideDuration={6000}
                >
                    <Alert {...snackbar} onClose={handleCloseSnackbar}/>
                </Snackbar>
            )}
            <Dialog
                open={openConfirmationDialog}
                onClose={handleCloseConfirmationDialog}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{cursor: 'move'}} id="draggable-dialog-title">
                    Confirm Deletion
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this customer? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseConfirmationDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmDeleteCustomer} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default CustomerTable;
