import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode';

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [userEmail, setUserEmail] = useState('');
    const [isAdmin, setIsAdmin] = useState(false);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const checkAuthentication = () => {
            const token = localStorage.getItem('token');
            if (token) {
                try {
                    const decodedToken = jwtDecode(token);
                    if (decodedToken.exp * 1000 < Date.now()) {
                        localStorage.removeItem('token');
                        setIsAuthenticated(false);
                        setIsAdmin(false);
                        navigate('/login', { replace: true });
                    } else {
                        setIsAuthenticated(true);
                        setIsAdmin(decodedToken.role === 'Admin');
                    }
                } catch (error) {
                    console.error("Token decoding failed", error);
                    localStorage.removeItem('token');
                    setIsAuthenticated(false);
                    setIsAdmin(false);
                    navigate('/login', { replace: true });
                }
            } else {
                navigate('/login', { replace: true });
            }
            setLoading(false);
        };

        checkAuthentication();
    }, [navigate]);

    const handleLogin = (email, token) => {
        localStorage.setItem('token', token);
        const decodedToken = jwtDecode(token);
        setIsAuthenticated(true);
        setIsAdmin(decodedToken.role === 'Admin');
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        setIsAuthenticated(false);
        setIsAdmin(false);
        setUserEmail('');
        navigate('/login', { replace: true });
    };

    if (loading) {
        return <div></div>;
    }

    return (
        <AuthContext.Provider value={{
            isAuthenticated,
            userEmail,
            isAdmin,
            handleLogin,
            handleLogout
        }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
