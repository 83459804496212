import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';

const ForecastSummary = () => {
    const [forecasts, setForecasts] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        fetchForecasts();
    }, []);

    const fetchForecasts = async () => {
        try {
            const response = await axios.get('/api/forecasts');
            const sortedForecasts = response.data.sort((a, b) => new Date(a.startDateUtc) - new Date(b.startDateUtc));
            setForecasts(sortedForecasts);
        } catch (error) {
            console.log(error);
        }
    };

    const handleForecastClick = (forecastId, isLocked) => {
        navigate(`/customer-forecast/${forecastId}`, { state: { isLocked } });
    };

    const formatDate = (date) => {
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        return new Date(date).toLocaleDateString('en-GB', options);
    };

    return (
        <div>
            <h1>Forecasts</h1>
            <h2>Unused for the time being - maybe we'll put something here to create Budget forecasts?</h2>
            {/*{forecasts.map((forecast) => (*/}
            {/*    <div*/}
            {/*        key={forecast.forecastId}*/}
            {/*        onClick={() => handleForecastClick(forecast.forecastId, forecast.isLocked)}*/}
            {/*        style={{*/}
            {/*            border: '1px solid black',*/}
            {/*            padding: '10px',*/}
            {/*            margin: '10px',*/}
            {/*            cursor: 'pointer',*/}
            {/*            position: 'relative'*/}
            {/*        }}*/}
            {/*    >*/}
            {/*        <h2>{forecast.name}</h2>*/}
            {/*        <p>Start Date: {formatDate(forecast.startDateUtc)}</p>*/}
            {/*        <p>Created: {formatDate(forecast.createdUtc)}</p>*/}
            {/*        <p>Updated: {formatDate(forecast.updatedUtc)}</p>*/}
            {/*        {forecast.isLocked ? (*/}
            {/*            <LockIcon*/}
            {/*                style={{*/}
            {/*                    position: 'absolute',*/}
            {/*                    bottom: '5px',*/}
            {/*                    right: '5px',*/}
            {/*                }}*/}
            {/*            />*/}
            {/*        ) : (*/}
            {/*            <LockOpenIcon*/}
            {/*                style={{*/}
            {/*                    position: 'absolute',*/}
            {/*                    bottom: '5px',*/}
            {/*                    right: '5px',*/}
            {/*                }}*/}
            {/*            />*/}
            {/*        )}*/}
            {/*    </div>*/}
            {/*))}*/}
        </div>
    );
};

export default ForecastSummary;
