import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useEffect} from 'react';
import {BarChart, PieChart, Gauge, gaugeClasses} from '@mui/x-charts';
import {Grid} from '@mui/material';

function Home() {
    const navigate = useNavigate();

    useEffect(() => {
        const isAuthenticated = localStorage.getItem('token');
        if (!isAuthenticated) {
            navigate('/login');
        }
    }, [navigate]);

    return (
        <div>
            <h1>Abakus home page</h1>
            <p>This page is under construction...</p>
        </div>
    );
}

export default Home;