import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const OutputsSummaryTable = ({ summaryData, view, startMonth }) => {
    const baseMonthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const getOrderedMonthNames = (startMonth) => {
        const monthNames = [...baseMonthNames];
        const rotationIndex = (startMonth - 1) % 12;

        return [...monthNames.slice(rotationIndex), ...monthNames.slice(0, rotationIndex)];
    };

    const monthNames = getOrderedMonthNames(startMonth);

    const currencyPrefix = view === 'grossRev' || view === 'netRev' || view === 'tradeSpend' || view === 'grossProfit' ? '£' : '';
    const percentPostfix = view === 'grossProfitPercent' ? '%' : '';

    const formatValue = (value) => {
        if (value === undefined) return 'N/A';

        const roundedValue = Math.round(value * 100) / 100;
        const displayValue = roundedValue % 1 === 0 ? roundedValue.toString() : roundedValue.toFixed(2);

        return `${currencyPrefix}${displayValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}${percentPostfix}`;
    };

    const calculateTotals = () => {
        let monthlyTotals = Array(12).fill(0);
        let itemCount = Array(12).fill(0);

        summaryData.channels.forEach(channel => {
            channel.customers.forEach(customer => {
                customer[view].forEach((value, index) => {
                    monthlyTotals[index] += value;
                    itemCount[index] += 1;
                });
            });
        });

        const fyTotal = view === 'grossProfitPercent'
            ? monthlyTotals.reduce((acc, value, index) => acc + value / itemCount[index], 0) / 12
            : monthlyTotals.reduce((acc, value) => acc + value, 0);

        return monthlyTotals.map((total, index) => formatValue(view === 'grossProfitPercent' ? total / itemCount[index] : total)).concat(formatValue(fyTotal));
    };

    const totals = calculateTotals();

    const calculateChannelTotals = (channel) => {
        const channelTotals = Array(12).fill(0);
        let itemCount = Array(12).fill(0);

        channel.customers.forEach(customer => {
            customer[view].forEach((value, index) => {
                channelTotals[index] += value;
                itemCount[index] += 1;
            });
        });

        const channelFyTotal = view === 'grossProfitPercent'
            ? channelTotals.reduce((acc, value, index) => acc + value / itemCount[index], 0) / 12
            : channelTotals.reduce((acc, value) => acc + value, 0);

        return channelTotals.map((total, index) => formatValue(view === 'grossProfitPercent' ? total / itemCount[index] : total)).concat(formatValue(channelFyTotal));
    };

    const calculateCustomerFyTotal = (customer) => {
        if (view === 'grossProfitPercent') {
            const sum = customer[view].reduce((acc, value) => acc + value, 0);
            return sum / 12;
        } else {
            return customer[view].reduce((acc, value) => acc + value, 0);
        }
    };

    return (
        <TableContainer component={Paper}>
            <Table aria-label="account summary table">
                <TableHead>
                    <TableRow sx={{ '& > *': { fontWeight: 'bold' } }}>
                        <TableCell></TableCell>
                        {monthNames.map((month) => (
                            <TableCell key={month} align="right">{month.toUpperCase()}</TableCell>
                        ))}
                        <TableCell align="right" sx={{ fontWeight: 'bold' }}>FY</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {/* Totals Row */}
                    <TableRow sx={{ '& > *': { fontWeight: 'bold' } }}>
                        <TableCell component="th" scope="row">TOTAL</TableCell>
                        {totals.map((total, index) => (
                            <TableCell align="right" key={index} sx={index === monthNames.length ? { fontWeight: 'bold' } : {}}>{total}</TableCell>
                        ))}
                    </TableRow>
                    {/* Channel and Data Rows */}
                    {summaryData.channels.map((channel) => (
                        <React.Fragment key={channel.channel}>
                            {/* Channel Totals Row */}
                            <TableRow sx={{ '& > *': { fontWeight: 'bold', backgroundColor: 'rgba(0, 0, 0, 0.04)' } }}>
                                <TableCell component="th" scope="row">{channel.channel}</TableCell>
                                {calculateChannelTotals(channel).map((total, index) => (
                                    <TableCell align="right" key={index} sx={index === monthNames.length ? { fontWeight: 'bold' } : {}}>{total}</TableCell>
                                ))}
                            </TableRow>
                            {/* Customers Rows */}
                            {channel.customers.map((customer) => (
                                <TableRow key={customer.name}>
                                    <TableCell component="th" scope="row">{customer.name}</TableCell>
                                    {customer[view].map((value, index) => (
                                        <TableCell align="right" key={index} sx={index === monthNames.length ? { fontWeight: 'bold' } : {}}>
                                            {formatValue(value)}
                                        </TableCell>
                                    ))}
                                    <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                                        {formatValue(calculateCustomerFyTotal(customer))}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </React.Fragment>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default OutputsSummaryTable;
