import React from 'react';
import { Container } from 'reactstrap';
import './Layout.css';

const Layout = ({ children }) => {
    return (
        <div className="layout-wrapper">
            <Container>
                {children}
            </Container>
        </div>
    );
};

Layout.displayName = 'Layout';

export default Layout;
