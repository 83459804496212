import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, TextField, Button, Typography, Paper, Snackbar, Alert } from '@mui/material';

function RegisterPage({ onRegister }) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('info');
    
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        const token = localStorage.getItem('token');
        
        const response = await fetch('/api/auth/register', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
                       'Authorization': `Bearer ${token}` },
            body: JSON.stringify({ email, password })
        });

        if (response.ok) {
            setSnackbarMessage('Registration successful! You can now log in.');
            setSnackbarSeverity('success');
            // Assuming onLogin will be implemented, call it here
            // onLogin();

            // Redirect to home page
            navigate('/');
        } else {
            const errors = await response.json();
            let message = '';
            if (Array.isArray(errors)) {
                message = errors.map(error => error.description).join(' ');
            } else if (errors && errors.message) {
                message = errors.message;
            } else {
                message = 'An error occurred during registration.';
            }
            setSnackbarMessage(message || 'An error occurred during registration.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    return (
        <Container component="main" maxWidth="xs">
            <Paper elevation={3} style={{ padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography component="h1" variant="h5" style={{ marginBottom: '20px' }}>
                    Register
                </Typography>
                <form onSubmit={handleSubmit} style={{ width: '100%', marginTop: '8px' }}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        style={{ margin: '24px 0 8px' }}
                    >
                        Register
                    </Button>
                </form>
            </Paper>
            <Snackbar open={snackbarOpen} anchorOrigin={{vertical: 'bottom', horizontal: 'center'}} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Container>
    );
}

export default RegisterPage;
