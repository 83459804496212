import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    LinearProgress,
    Snackbar,
    Alert,
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import { Button } from "reactstrap";
import TextField from "@mui/material/TextField";
import ProductDropdown from "./ProductDropdown";
import './SkuBaseForecastTable.css';
import { isMoneyGreaterThanZero, isMoneyGreaterThanZeroThreeDecimals, isWholeNumber } from '../Utilities/validation';
import axiosInstance from "../AxiosInstance";
import { getColumnWidthsFromStorage, saveColumnWidthsToStorage } from '../Utilities/columnWidthUtils';

const defaultColumnWidths = {
    skuDescription: 100
};

const SkuBaseForecastTable = ({ forecastId, forecastMonth, customerId, isLocked, onForecastsChange, refresh, onRefreshed, promotionProductsData }) => {
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [rows, setRows] = useState([]);
    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState({
        unitCost: 0,
        unitLogs: 0,
        recommendedRetailPrice: 0,
        distribution: 0,
        rateOfSale: 0,
        productIds: [],
    });
    const [productErrorText, setProductErrorText] = useState('');
    const [unitCostErrorText, setUnitCostErrorText] = useState('');
    const [unitLogsErrorText, setUnitLogsErrorText] = useState('');
    const [recommendedRetailPriceErrorText, setRecommendedRetailPriceErrorText] = useState('');
    const [distributionErrorText, setDistributionErrorText] = useState('');
    const [rateOfSaleErrorText, setRateOfSaleErrorText] = useState('');
    const [editedRows, setEditedRows] = useState({});
    const [snackbar, setSnackbar] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [skuBaseForecastToDelete, setSkuBaseForecastToDelete] = useState(null);
    const [columnWidths, setColumnWidths] = useState(getColumnWidthsFromStorage('sbf', defaultColumnWidths));
    const [products, setProducts] = useState([]); // Add this state

    const handleColumnResize = (params) => {
        const updatedWidths = { ...columnWidths, [params.colDef.field]: params.width };
        setColumnWidths(updatedWidths);
        saveColumnWidthsToStorage('sbf', updatedWidths);
    };

    useEffect(() => {
        fetchForecasts();
    }, [forecastId, forecastMonth, customerId]);

    useEffect(() => {
        if (refresh) {
            fetchForecasts();
            onRefreshed();
        }
    }, [refresh, onRefreshed]);

    const handleCloseSnackbar = () => setSnackbar(null);

    const handleClickCreate = () => {
        setOpen(true);
        setFormData({
            unitCost: 0,
            unitLogs: 0,
            recommendedRetailPrice: 0,
            distribution: 0,
            rateOfSale: 0,
            productIds: []
        });
        setProductErrorText('');
        setUnitCostErrorText('');
        setUnitLogsErrorText('');
        setRecommendedRetailPriceErrorText('');
        setDistributionErrorText('');
        setRateOfSaleErrorText('');
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpenDeleteDialog = (skuBaseForecastId) => {
        setSkuBaseForecastToDelete(skuBaseForecastId);
        setDeleteDialogOpen(true);
    };

    const handleCloseDeleteDialog = () => {
        setDeleteDialogOpen(false);
        setSkuBaseForecastToDelete(null);
    };

    const handleConfirmDelete = async () => {
        if (!skuBaseForecastToDelete) return;

        try {
            const response = await axiosInstance.delete(`/forecasts/${forecastId}/skubaseforecasts/${skuBaseForecastToDelete}?month=${forecastMonth}`);

            if (response.status === 200) {
                setRows(rows.filter(row => row.id !== skuBaseForecastToDelete));
                setSnackbar({ children: "Sku Base Forecast deleted successfully.", severity: "success" });
            }
            setSelectedRowId(null);
            await fetchForecasts();
        } catch (error) {
            console.error(error.response);
            const errorMessage = typeof error.response?.data === 'string' ? error.response.data : 'Error deleting sku base forecast.';
            setSnackbar({ children: errorMessage, severity: 'error' });
        } finally {
            handleCloseDeleteDialog();
        }
    };

    const handleCreateSkuBaseForecast = async (data) => {
        const isValidUnitCost = isMoneyGreaterThanZeroThreeDecimals(data.unitCost);
        const isValidUnitLogs = isMoneyGreaterThanZero(data.unitLogs);
        const isValidRecommendedRetailPrice = isMoneyGreaterThanZero(data.recommendedRetailPrice);
        const isValidDistribution = validationLogicForDistribution(data.distribution);
        const isValidRateOfSale = validationLogicForRateOfSale(data.rateOfSale);
        const isValidProducts = data.productIds.length > 0;

        if (!isValidUnitCost ||
            !isValidUnitLogs ||
            !isValidRecommendedRetailPrice ||
            !isValidDistribution ||
            !isValidRateOfSale ||
            !isValidProducts) {
            setUnitCostErrorText(isValidUnitCost ? '' : 'Please enter a valid number with up to three decimal places and greater than 0');
            setUnitLogsErrorText(isValidUnitLogs ? '' : 'Please enter a valid number with up to two decimal places and greater than 0');
            setRecommendedRetailPriceErrorText(isValidRecommendedRetailPrice ? '' : 'Please enter a valid number with up to two decimal places and greater than 0');
            setDistributionErrorText(isValidDistribution ? '' : 'Please enter a valid number greater than 0');
            setRateOfSaleErrorText(isValidRateOfSale ? '' : 'Please enter a valid number greater than 0');
            setProductErrorText(isValidProducts ? '' : 'Please select at least one SKU');
            return;
        }

        setUnitCostErrorText('');
        setUnitLogsErrorText('');
        setRecommendedRetailPriceErrorText('');
        setDistributionErrorText('');
        setRateOfSaleErrorText('');
        setProductErrorText('');

        try {
            data.customerId = customerId;
            data.month = forecastMonth;

            // Create SkuBaseForecast for each selected product using Promise.allSettled
            const requests = data.productIds.map(productId => {
                const forecastData = { ...data, productId };
                return axiosInstance.post(`/forecasts/${forecastId}/skubaseforecasts`, forecastData);
            });

            const results = await Promise.allSettled(requests);

            // Process results
            const successful = [];
            const failed = [];

            results.forEach((result, index) => {
                if (result.status === 'fulfilled') {
                    successful.push(data.productIds[index]);
                } else {
                    failed.push(data.productIds[index]);
                }
            });

            if (successful.length > 0) {
                handleClose();
                await fetchForecasts();
            }

            if (failed.length > 0) {
                const failedProductNames = failed.map(id => {
                    const product = products.find(p => p.productId === id);
                    return product ? product.skuDescription : `Product ID: ${id}`;
                }).join(', ');

                setSnackbar({
                    children: `Failed to add the following SKUs: ${failedProductNames}`,
                    severity: 'error',
                });
            } else if (successful.length > 0) {
                setSnackbar({
                    children: 'Sku Base Forecasts created successfully.',
                    severity: 'success',
                });
            } else {
                setSnackbar({
                    children: 'Error creating Sku Base Forecasts.',
                    severity: 'error',
                });
            }
        } catch (error) {
            console.error('Error creating SkuBaseForecast:', error);
            let errorMessage = 'Error creating Sku Base Forecasts.';
            if (error.response && error.response.status === 500) {
                errorMessage = error.response.data || 'Server error while creating Sku Base Forecasts.';
            }
            setSnackbar({ children: errorMessage, severity: 'error' });
        }
    };

    const validationLogicForDistribution = (value) => {
        const regex = /^[1-9]\d*$/;
        return regex.test(value);
    };

    const validationLogicForRateOfSale = (value) => {
        const regex = /^\d+(\.\d{1,2})?$/;
        return regex.test(value) && parseFloat(value) > 0;
    };

    const isValidWeeklyDistributionRates = (weeklyDistributionRates) => {
        return weeklyDistributionRates.every(rateObj => {
            const intValue = parseInt(rateObj.actualDistributionRate, 10);
            return !isNaN(intValue) && intValue >= 0 && intValue === parseFloat(rateObj.actualDistributionRate);
        });
    };

    const isValidRateOfSale = (value) => {
        const floatValue = parseFloat(value);

        if (isNaN(floatValue) || floatValue < 0) {
            return false;
        }

        const roundedValue = Math.round(floatValue * 100) / 100;

        return floatValue === roundedValue;
    };

    const fetchForecasts = async () => {
        try {
            const response = await axiosInstance.get(`/forecasts/${forecastId}/skubaseforecasts/${customerId}?month=${forecastMonth}`);
            const forecasts = response.data;
            setRows(forecasts);
            onForecastsChange(forecasts);
        } catch (error) {
            console.log(error);
        }
    };

    const transformEditedRowToOverrideRequest = (editedRow, originalRow, forecastMonth) => {
        const distributionRateOverrides = getChangedWeeklyDistributionRates(
            editedRow.weeklyDistributionRates,
            originalRow.weeklyDistributionRates
        ).map(change => {
            return {
                SkuBaseForecastId: editedRow.id,
                WeekStart: change.date,
                DistributionRate: change.actualDistributionRate,
                Month: forecastMonth
            };
        });

        let skuBaseForecastOverride = null;
        if (editedRow.unitCost !== originalRow.unitCost ||
            editedRow.unitLogs !== originalRow.unitLogs ||
            editedRow.recommendedRetailPrice !== originalRow.recommendedRetailPrice ||
            editedRow.distribution !== originalRow.distribution ||
            editedRow.rateOfSale !== originalRow.rateOfSale) {
            skuBaseForecastOverride = {
                SkuBaseForecastId: editedRow.id,
                UnitCost: parseFloat(editedRow.unitCost || 0),
                UnitLogs: parseFloat(editedRow.unitLogs || 0),
                RecommendedRetailPrice: parseFloat(editedRow.recommendedRetailPrice || 0),
                Distribution: parseInt(editedRow.distribution || 0, 10),
                RateOfSale: parseFloat(editedRow.rateOfSale || 0),
                Month: forecastMonth
            };
        }

        return {
            DistributionRateOverrides: distributionRateOverrides,
            SkuBaseForecastOverride: skuBaseForecastOverride
        };
    };

    const handleSaveClick = async () => {
        try {
            const rowsArray = Object.values(editedRows);

            const transformedRows = rowsArray.map(editedRow => {
                const originalRow = rows.find(row => row.id === editedRow.id);
                return transformEditedRowToOverrideRequest(editedRow, originalRow, forecastMonth);
            });

            const payload = {
                DistributionRateOverrides: [].concat(...transformedRows.map(row => row.DistributionRateOverrides)),
                SkuBaseForecastOverrides: transformedRows.map(row => row.SkuBaseForecastOverride).filter(override => override !== null)
            };

            await axiosInstance.post(`/forecasts/${forecastId}/skubaseforecasts/overrides/bulk`, payload);

            setSnackbar({ children: 'Changes successfully updated.', severity: 'success' });
            setEditedRows({});
            await fetchForecasts();
        } catch (error) {
            console.error(error.response);
            const errorMessage = typeof error.response?.data === 'string' ? error.response.data : 'Error saving changes.';
            setSnackbar({ children: errorMessage, severity: 'error' });
        }
    }

    const CustomToolbar = () => {
        return (
            <GridToolbarContainer sx={{ justifyContent: 'flex-end' }}>
                <IconButton aria-label="create" onClick={handleClickCreate} disabled={isLocked}>
                    <AddIcon />
                </IconButton>
                <IconButton aria-label="save" onClick={handleSaveClick}
                            disabled={Object.keys(editedRows).length === 0}>
                    <SaveIcon />
                </IconButton>
                <IconButton aria-label="delete" onClick={() => handleOpenDeleteDialog(selectedRowId)} disabled={!selectedRowId || isLocked}>
                    <DeleteIcon />
                </IconButton>
            </GridToolbarContainer>
        );
    };

    const handleProductSelected = (productIds) => {
        setFormData((prevState) => ({
            ...prevState,
            productIds: productIds,
        }));
    };

    const handleTextFieldChange = (event) => {
        const { name, value } = event.target;

        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    function moveTopLevelDateKeysToNested(newRow) {
        const weeklyRatesMap = newRow.weeklyDistributionRates.reduce((acc, current) => {
            acc[current.week] = current;
            return acc;
        }, {});

        const datePattern = /^\d{2}-\d{2}-\d{4}$/;

        Object.keys(newRow).forEach(key => {
            if (datePattern.test(key) && weeklyRatesMap[key]) {
                weeklyRatesMap[key].actualDistributionRate = newRow[key];
                delete newRow[key];
            }
        });

        newRow.weeklyDistributionRates = Object.values(weeklyRatesMap);

        return newRow;
    }

    const getChangedWeeklyDistributionRates = (newRates, oldRates) => {
        const changes = [];
        for (const key of Object.keys(newRates)) {
            if (oldRates.hasOwnProperty(key)) {
                const oldValue = oldRates[key].actualDistributionRate;
                const newValue = newRates[key].actualDistributionRate;
                
                if (!isNaN(oldValue) && !isNaN(newValue) && newValue !== oldValue) {
                    changes.push({ date: newRates[key].week, actualDistributionRate: newValue });
                }
            }
        }
        return changes;
    };

    const processRowUpdates = (newRow, oldRow) => {
        let validationErrors = [];

        const copiedNewRow = JSON.parse(JSON.stringify(newRow));
        moveTopLevelDateKeysToNested(copiedNewRow);
        const changedRates = getChangedWeeklyDistributionRates(copiedNewRow.weeklyDistributionRates, oldRow.weeklyDistributionRates);

        if (!isValidWeeklyDistributionRates(changedRates)) {
            validationErrors.push("Please enter a valid value for weekly distribution rate.");
            copiedNewRow.weeklyDistributionRates = oldRow.weeklyDistributionRates;
        }

        if (!isMoneyGreaterThanZero(newRow.unitCost)) {
            validationErrors.push("Please enter a valid value for unit cost.");
            copiedNewRow.unitCost = oldRow.unitCost;
        }

        if (!isMoneyGreaterThanZero(newRow.unitLogs)) {
            validationErrors.push("Please enter a valid value for unit logs.");
            copiedNewRow.unitLogs = oldRow.unitLogs;
        }

        if (!isMoneyGreaterThanZero(newRow.recommendedRetailPrice)) {
            validationErrors.push("Please enter a valid value for RRP.");
            copiedNewRow.recommendedRetailPrice = oldRow.recommendedRetailPrice;
        }

        if (!isValidRateOfSale(newRow.rateOfSale)) {
            validationErrors.push("Please enter a valid value for ROS.");
            copiedNewRow.rateOfSale = oldRow.rateOfSale;
        }

        if (!isWholeNumber(newRow.distribution)) {
            validationErrors.push("Please enter a valid value for distribution.");
            copiedNewRow.distribution = oldRow.distribution;
        }
        
        setEditedRows(prevState => ({
            ...prevState,
            [copiedNewRow.id]: copiedNewRow
        }));

        if (validationErrors.length > 0) {
            const combinedErrorMessage = validationErrors.join("\n");
            setSnackbar({
                children: combinedErrorMessage,
                severity: 'error'
            });
        }

        return copiedNewRow;
    }

    const handleSelectionModelChange = (newSelectionModel) => {
        const newSelectedRowId = newSelectionModel[0] || null;
        setSelectedRowId(newSelectedRowId);
    };

    const handleProcessRowUpdateError = useCallback((error) => {
        setSnackbar({ children: error.message, severity: 'error' });
    }, []);

    const isHighlighted = (params, promotionProductsData) => {
        if (!promotionProductsData) {
            return false;
        }

        const skuBaseForecastId = params.id;
        const week = params.colDef.field;

        const matchingProduct = promotionProductsData.find(product => product.skuBaseForecastId === skuBaseForecastId);
        if (!matchingProduct) return false;
        const matchingWeek = matchingProduct.weeklyDistributionRates.find(rate => rate === week);
        return !!matchingWeek;
    };

    const columns = useMemo(() => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const weeklyDistributionRateColumns = rows.length && rows[0]?.weeklyDistributionRates
            ? rows[0].weeklyDistributionRates.map(wdr => {
                const [day, month, year] = wdr.week.split('-').map(Number);
                const columnDate = new Date(year, month - 1, day);

                let isEditable = columnDate > today && !isLocked;

                return {
                    field: wdr.week,
                    headerName: wdr.week,
                    type: 'number',
                    width: 100,
                    editable: isEditable,
                    valueGetter: (value, row) => row.weeklyDistributionRates.find(w => w.week === wdr.week)?.actualDistributionRate,
                    cellClassName: (params) => {
                        const classes = [];
                        if (isHighlighted(params, promotionProductsData)) classes.push('highlightedCell');
                        if (!isEditable) classes.push('nonEditableCell');
                        return classes.join(' ');
                    }
                };
            })
            : [];

        const fyTotalColumn = {
            field: 'fyTotal',
            headerName: 'FY Total',
            type: 'number',
            width: 120,
            valueGetter: (value, row) => {
                return row.weeklyDistributionRates.reduce((sum, current) => sum + (current.actualDistributionRate || 0), 0);
            },
            cellClassName: 'nonEditableCell'
        };

        return [
            { field: 'id', headerName: 'ID', width: 90 },
            {
                field: 'skuDescription',
                headerName: 'SKU',
                width: columnWidths.skuDescription,
                editable: false,
            },
            {
                field: 'unitCost',
                headerName: 'Unit Cost',
                type: 'number',
                width: 100,
                editable: true,
                valueFormatter: (value) => '£' + parseFloat(value).toFixed(3),
            },
            {
                field: 'unitLogs',
                headerName: 'Logs',
                type: 'number',
                width: 100,
                editable: true,
                valueFormatter: (value) => '£' + parseFloat(value).toFixed(2),
            },
            {
                field: 'recommendedRetailPrice',
                headerName: 'RRP',
                type: 'number',
                width: 100,
                editable: true,
                valueFormatter: (value) => '£' + parseFloat(value).toFixed(2),
            },
            {
                field: 'distribution',
                headerName: 'Distribution',
                type: 'number',
                width: 100,
                editable: true,
            },
            {
                field: 'rateOfSale',
                headerName: 'ROS',
                type: 'number',
                width: 100,
                editable: true,
            },
            ...weeklyDistributionRateColumns,
            fyTotalColumn
        ];
    }, [rows, isLocked, columnWidths, promotionProductsData]);


    return (
        <Box sx={{ height: 400, width: '100%' }}>
            <DataGrid
                editMode="row"
                rows={rows}
                columns={columns}
                onColumnResize={handleColumnResize}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10,
                        },
                    },
                    columns: {
                        columnVisibilityModel: {
                            id: false,
                        },
                    },
                }}
                pageSizeOptions={[10]}
                slots={{
                    toolbar: CustomToolbar,
                    loadingOverlay: LinearProgress,
                }}
                processRowUpdate={processRowUpdates}
                onProcessRowUpdateError={handleProcessRowUpdateError}
                selectionModel={selectedRowId ? [selectedRowId] : []}
                onSelectionModelChange={handleSelectionModelChange}
                onRowClick={(params) => setSelectedRowId(params.id)}
            />
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Create SkuBaseForecast</DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{ pb: 2 }}>
                        Enter the details for the new SkuBaseForecast.
                    </DialogContentText>
                    <Box sx={{ pb: 2 }}>
                        <ProductDropdown
                            onProductsSelected={handleProductSelected}
                            productErrorText={productErrorText}
                            setProducts={setProducts} // Pass setProducts as a prop
                        />
                    </Box>
                    <Box sx={{ pb: 2 }}>
                        <TextField
                            label="Customer Unit Price"
                            name="unitCost"
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                            value={formData.unitCost}
                            error={Boolean(unitCostErrorText)}
                            helperText={unitCostErrorText}
                            onChange={handleTextFieldChange}
                        />
                    </Box>
                    <Box sx={{ pb: 2 }}>
                        <TextField
                            label="RRP"
                            name="recommendedRetailPrice"
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                            value={formData.recommendedRetailPrice}
                            error={Boolean(recommendedRetailPriceErrorText)}
                            helperText={recommendedRetailPriceErrorText}
                            onChange={handleTextFieldChange}
                        />
                    </Box>
                    <Box sx={{ pb: 2 }}>
                        <TextField
                            label="Distribution"
                            name="distribution"
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                            value={formData.distribution}
                            error={Boolean(distributionErrorText)}
                            helperText={distributionErrorText}
                            onChange={handleTextFieldChange}
                        />
                    </Box>
                    <Box sx={{ pb: 2 }}>
                        <TextField
                            label="Rate of Sale"
                            name="rateOfSale"
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                            value={formData.rateOfSale}
                            error={Boolean(rateOfSaleErrorText)}
                            helperText={rateOfSaleErrorText}
                            onChange={handleTextFieldChange}
                        />
                    </Box>
                    <Box sx={{ pb: 2 }}>
                        <TextField
                            label="Customer Unit Logs"
                            name="unitLogs"
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                            value={formData.unitLogs}
                            error={Boolean(unitLogsErrorText)}
                            helperText={unitLogsErrorText}
                            onChange={handleTextFieldChange}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={() => handleCreateSkuBaseForecast(formData)}>Create</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={deleteDialogOpen}
                onClose={handleCloseDeleteDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Delete Sku Base Forecast"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this sku base forecast? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
                    <Button onClick={handleConfirmDelete} autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
            {!!snackbar && (
                <Snackbar
                    open
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    onClose={handleCloseSnackbar}
                    autoHideDuration={6000}
                >
                    <Alert {...snackbar} onClose={handleCloseSnackbar} />
                </Snackbar>
            )}
        </Box>
    );
};

export default SkuBaseForecastTable;
